.welcome-screen {
    background: linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%);

    h2 {
        font-size: 18px;
        @media(min-width: 768px){
            font-size: 26px;
        }
    }

    span {
        font-size: 12px;
        color: #828282;
        @media(min-width: 768px){
            font-size: 13px;
        }
    }
}
.sidebar-wrapper {
    @media(min-width: 768px){
        width: 380px !important;
        background: linear-gradient(180deg, rgba(255, 246, 240, 0.78) 0%, rgba(251, 232, 233, 0.31) 57.44%, rgba(252, 252, 255, 0.57) 100%);
    }

    .sidebar {
        width: 92%;
        @media(max-width:767px){
            width: 100%;
            background: linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%);
        }
    }
}
.info-box{
    background: linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%);
    box-shadow: -10px 48.5967px 140px rgba(126, 123, 160, 0.2);
    .truncate{
        padding-top: 1px;
    }
}
.graph-wrapper{
    .graph-title, .dropdown-list{
        color: #4C4C66;
    }
    .day-select-list{
        .data-selected-val{
            color: #2B3646;
            &:after{
                content: "";
                width: 26px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                background: url(../../assets/images/icons/dropdown-arrow.svg) no-repeat center center;
            }
        }
    }
    .dropdown-list{
        li{
            &:hover{
                background-color: grey;
                color: #fff;
            }
        }
    }
    .graph-container{
        background: linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%);
        box-shadow: -13px 48.5967px 140px rgba(126, 123, 160, 0.2);
    }
    @media(min-width:768px){
        margin-top: -6px;
    }
    canvas{
        width: 100% !important;
    }
}
